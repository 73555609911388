import { OktaAuthOptions } from "@okta/okta-auth-js";

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  account: IAccountItem[];
  status: string;
  createdDate: string;
  userGroups: any[];
  roles: any[];
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
}

export interface IAccount {
  status: string;
  id: string;
  name: string;
  tenantId: string;
  description: string;
  logoUrl: string;
}

export interface IAccountTest {
  status: string;
  id: string;
  name: string;
  tenantId: string;
  description: string; 
  primary: boolean;
  logoUrl: string;
}

export interface IAccountItem {
  status: string;
  account: IAccount;
  primary: boolean;
}

export interface IAccountResponse {
  content: IUser;
}

export interface ApiAccountsResults {
  results: IAccountItem[],
}

export interface ApiEnvironmentResults {
  results: IEnvironment[]
}

export interface IEnvironment {
  id: number;
  name: string;
  description: string;
  stage: string;
  status: string;
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
  account: IAccount;
  stacks: any[]
}


export interface ApiResponse<T> {
  content: T;
  errors: any[];
  message: string;
  trackingId: any;
}


export enum DeploymentStage {
  LOCAL = "LOCAL",
  DEVELOPMENT = "DEVELOPMENT",
  TEST = "TEST",
  PREPROD = "PREPROD",
  PRODUCTION = "PRODUCTION",
}


interface IOktaConfig {
  oidc: OktaAuthOptions
  widget: {
    USE_CLASSIC_ENGINE: boolean,
  },
}


export interface EnvironmentConfig {
  production: boolean;
  stage: DeploymentStage;
  ApiAuthentication: boolean;
  baseApiHost: string;
  oktaConfiguration: IOktaConfig,
}
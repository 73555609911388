<div class="page-container">
  <div class="page-header">
    <h1 class="page-title">{{ pageTitle }}</h1>
  </div>
  <div class="page-content">
    <app-loading *ngIf="isLoading" />
    <app-error-box *ngIf="showError" [errorMessage]="errorMessage" />
    <div *ngIf="!isLoading" class="px-4">
      @for (environment of environments; track environment.id) {
      <div class="card"
        [tabindex]="environment.id"
        role="button"
        aria-pressed="false"
        (click)="selectEventHandler(environment)"
      >
        <div class="card-body">
          <div class="logo">
            <!-- <img *ngIf="isValidUrl(account.logoUrl)" [src]="account.logoUrl" alt="{{ account.name }} logo" /> -->
            <i class="bi bi-hdd-stack"></i> 
            <!-- <img src="assets/server.png" alt="{{ environment.name }}" /> -->
          </div>
          <div class="">
            <div class="large">{{ environment.name }}</div>
            <div class="small">{{ environment.description }}</div>
            
          </div>
        </div>
      </div>
    }
    </div>
  </div>
</div>

<!-- 
<div class="page-header">
    <h1 class="page-title">{{ pageTitle }}</h1>
  </div>
  <app-loading *ngIf="isLoading" />
  <app-error-box *ngIf="showError" [errorMessage]="errorMessage" />
  <div *ngIf="!isLoading">
    @for (environment of environments; track environment.id) {
      <div class="card"
        [tabindex]="environment.id"
        role="button"
        aria-pressed="false"
        (click)="selectEventHandler(environment)"
      >
        <div class="card-body">
          <div>[logo]</div>
          <div>{{ environment.name }}</div>
        </div>
      </div>
    }
  </div>

-->


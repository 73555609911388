import { Component, OnDestroy, OnInit } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

export class AppComponent implements OnInit, OnDestroy {
  constructor() {}

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }
  
}

<div class="page-container">
  <div class="page-header">
    <h1 class="page-title">{{ pageTitle }}</h1>
  </div>
  <div class="page-content">
    <app-loading *ngIf="isLoading" />
    <div *ngIf="!isLoading" class="px-4">
      @for (account of accounts; track account.id) { 
        <div class="card"
          [tabindex]="account.id"
          role="button"
          aria-pressed="false"
          (click)="selectEventHandler(account)"
        >
          <div class="card-body">
            <div class="logo">
              <img *ngIf="isValidUrl(account.logoUrl)" [src]="account.logoUrl" alt="{{ account.name }} logo" />
              <i *ngIf="!isValidUrl(account.logoUrl)" class="bi bi-image"></i>
            </div>
            <div> 
              {{ account.name }}
              <span *ngIf="account.primary" class="small">(primary)</span>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSelectorComponent } from './account-selector/account-selector.component';
import { EnvironmentSelectorComponent } from './environment-selector/environment-selector.component';
import { LaunchpadComponent } from './launchpad/launchpad.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    AccountSelectorComponent,
    EnvironmentSelectorComponent,
    LaunchpadComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    LaunchpadComponent,
    AccountSelectorComponent,
    EnvironmentSelectorComponent
  ],
})
export class LaunchpadModule { }

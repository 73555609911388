import { Component, OnDestroy, OnInit } from '@angular/core';
import OktaSignIn, { RenderOptions, RenderResultSuccessOIDC, WidgetOptions } from '@okta/okta-signin-widget';
import { AuthenticationService } from '../authentication.service';
import { environment } from '../../../environments/environment';


// Application imports
const widgetOptions: WidgetOptions = {
  ...environment.oktaConfiguration.oidc,
  logo: 'https://verato.com/wp-content/uploads/2023/04/RGB-verato-logo-R-vector-website.svg',
  features: {
   idpDiscovery: false,
  },
 }

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit, OnDestroy {
  public signIn: OktaSignIn;
  public isSignedIn: boolean = false;

  constructor(
    private authService: AuthenticationService,
  ){
    let signInOpts = {
      ...widgetOptions,
      authClient: this.authService,
    } as WidgetOptions;
    this.signIn = new OktaSignIn(signInOpts);
  }

  public async ngOnInit(): Promise<void> {
    await this.handleLogin(); 
  }

  private async handleLogin(): Promise<void> {
    const result = await this.signIn.showSignIn({
      el: '#login_form',
      scopes: environment.oktaConfiguration.oidc.scopes,
    } as RenderOptions);

    if (result.status == 'SUCCESS') {
      let resultSuccessOidc = result as RenderResultSuccessOIDC;
      await this.authService.handleLoginRedirect(resultSuccessOidc.tokens);
      // await this.authService.sessionPolling();
    } else {
      this.signIn.hide();
      await this.handleLogin();
    }
  }

  public ngOnDestroy(): void {
      
  }

}

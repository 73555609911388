import { HttpInterceptorFn, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthenticationService } from './authentication.service';

/**
 * Token Interceptor that attaches an `Authorization` header to outgoing HTTP requests.
 *
 * This interceptor is designed to seamlessly handle authentication by adding a Bearer token
 * to the `Authorization` header of HTTP requests. The token is retrieved from the 
 * `AuthenticationService`. If the token is `undefined`, the request is forwarded without 
 * modification.
 *
 * @param req - The outgoing HTTP request that is intercepted. The generic type `unknown`
 *              allows this interceptor to be used with requests of any type.
 * @param next - The next handler in the HTTP pipeline, responsible for passing the request 
 *               to the next interceptor or to the backend server.
 * @returns An observable that represents the HTTP event stream, either for the modified 
 *          request with the `Authorization` header or the original request if no token is available.
 */
export const tokenInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  // Inject the AuthenticationService to retrieve the access token.
  const authService = inject(AuthenticationService);

  // Retrieve the access token, which may be a string or undefined.
  const token: string | undefined = authService.getAccessToken();

  // Clone the request and add the Authorization header if the token is available.
  const reqWithAuthHeader = token
    ? req.clone({
        headers: req.headers
          .append('Authorization', `Bearer ${token.trim()}`)
      })
    : req;

  // Pass the request to the next handler in the chain.
  return next(reqWithAuthHeader);
};

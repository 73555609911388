import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { count, delay, lastValueFrom, retry, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient,
  ) { }
  
  /**
   * Makes a GET request to the specified URL and returns a promise that resolves with the response data.
   *
   * @param url - The URL to send the GET request to.
   * @returns A promise that resolves with the response data of type `T`.
   *
   * @throws If the request fails, the error is caught and handled by the `handleError` method.
   *
   * @example
   * ```typescript
   * const data = await apiService.get<MyDataType>('https://example.com/api/data');
   * ```
   */
  async get<T>(url: string): Promise<T> {
    try {
      return await lastValueFrom(this.http.get<T>(url));
    } catch (error: any) {
      this.handleError(error);
    }
  }

  /**
   * Makes a GET request to the specified URL with retry logic. If the request fails, it will retry the request
   * the specified number of times, with a 500ms delay between each retry attempt.
   *
   * @param url - The URL to send the GET request to.
   * @param attempts - The number of retry attempts if the request fails.
   * @returns A promise that resolves with the response data of type `T`.
   *
   * @throws If all retry attempts fail, the error is caught and handled by the `handleError` method.
   *
   * @example
   * ```typescript
   * const data = await apiService.getWithRetry<MyDataType>('https://example.com/api/data', 3);
   * ```
   */
  async getWithRetry<T>(url: string, attempts: number): Promise<T> {
    try {
      return await lastValueFrom(
        this.http.get<T>(url).pipe(
          retry({
            count: attempts,
            delay: (error, retryAttempt) => timer(100 * retryAttempt),
          })
        )
      );
    } catch (error: any) {
      this.handleError(error);
    }
  }

  /**
   * Makes a GET request to the specified URL with the provided query parameters and returns a promise that resolves with the response data.
   *
   * @param url - The URL to send the GET request to.
   * @param params - The query parameters to include with the GET request.
   * @returns A promise that resolves with the response data of type `T`.
   *
   * @throws If the request fails, the error is caught and handled by the `handleError` method.
   *
   * @example
   * ```typescript
   * const params = new HttpParams().set('key1', 'value1').set('key2', 'value2');
   * const data = await apiService.getWithParams<MyDataType>('https://example.com/api/data', params);
   * ```
   */
  async getWithParams<T>(url: string, params: HttpParams): Promise<T> {
    try {
      const options = {
        params: params,
      };
      return await lastValueFrom(this.http.get<T>(url, options));
    } catch (error: any) {
      this.handleError(error);
    }
  }

  private handleError(error: HttpErrorResponse): never {
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // Backend returned an unsuccessful response code
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    throw new Error(errorMessage);
  }
}

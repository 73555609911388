import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading/loading.component';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { ItemListComponent } from './item-list/item-list.component';
import { ErrorBoxComponent } from './error-box/error-box.component';



@NgModule({
  declarations: [
    LoadingComponent,
    MenuComponent,
    ItemListComponent,
    ErrorBoxComponent
  ],
  exports: [
    LoadingComponent,
    MenuComponent,
    ItemListComponent,
    ErrorBoxComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
  ]
})
export class SharedModule { }

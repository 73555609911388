import { DeploymentStage, EnvironmentConfig } from "../app/shared/models";

export const environment: EnvironmentConfig = {
  production: false,
  stage: DeploymentStage.TEST,
  ApiAuthentication: true,
  baseApiHost: 'https://console-service.test.verato-rd.com',
  oktaConfiguration: {
    oidc: {
      issuer: 'https://auth.test.verato-rd.com/oauth2/default',
      clientId: '0oahu05trthWgo7bS1d7',
      redirectUri: window.location.origin + '/login/callback',
      scopes: ['openid', 'email', 'profile'],
      tokenManager: {
        autoRenew: true,
        storage: 'sessionStorage'
      },
      services: {
        autoRenew: false,
        autoRemove: false,
      },
      cookies: {
        secure: true,
        sameSite: 'lax',
      },
    },
    widget: {
      USE_CLASSIC_ENGINE: false,
    },
  }
};

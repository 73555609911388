import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss'
})
export class LogoutComponent implements OnInit {
  
  constructor(
    private authService: AuthenticationService,
  ) {}

  public async ngOnInit(): Promise<void> {
      await this.authService.signOut({
        clearTokensBeforeRedirect: true,
      });
  }
  

}
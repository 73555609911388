import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import OktaAuth, { TokenParams } from '@okta/okta-auth-js';
import { Router } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';


// OKTA DEPS
import {
  OktaAuthModule,
  OktaConfig
} from '@okta/okta-angular';

// Application imports
import { AppRoutingModule } from './app-routing.module';
import { tokenInterceptor } from './authentication/token.interceptor';
import { LaunchpadModule } from './launchpad/launchpad.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

function onAuthRequired(oktaAuth: OktaAuth, injector: Injector, options?: TokenParams) {
  // `options` object can contain `acrValues` if it was provided in route data

  // Use injector to access any service available within your application
  const router = injector.get(Router);

  // Redirect the user to your custom login page
  router.navigate(['/login/callback']);
}

const oktaAuth = new OktaAuth(environment.oktaConfiguration.oidc);
const moduleConfig: OktaConfig = { oktaAuth, onAuthRequired };

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    OktaAuthModule.forRoot(moduleConfig),
    AppRoutingModule,
    SharedModule,
    LaunchpadModule,
    AuthenticationModule,
  ],
  exports: [
    SharedModule
  ],
  providers: [
    provideHttpClient(
      withInterceptors([tokenInterceptor]),
    ),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

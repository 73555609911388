<div class="menu-container">
  <ul class="nav justify-content-center">
    <li class="nav-item">
      <a 
        class="nav-link"
        [routerLink]="['/']"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }" 
        aria-current="page"
      >
        Launchpad
    </a>
    </li>
    <li class="nav-item">
      <a 
        class="nav-link"
        [routerLink]="['accounts']"
        routerLinkActive="active"
      >
        Accounts
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        [routerLink]="['environments']"
        routerLinkActive="active"
    >
      Environments
    </a>
    </li>
  </ul>
</div>


import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';

// Console Web Components
import { AccountSelectorComponent } from './launchpad/account-selector/account-selector.component';
import { EnvironmentSelectorComponent } from './launchpad/environment-selector/environment-selector.component';
import { LaunchpadComponent } from './launchpad/launchpad/launchpad.component';
import { LoginComponent } from './authentication/login/login.component';
import { LogoutComponent } from './authentication/logout/logout.component';


const routes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'login/callback',
    title: 'Verato - Sign In',
    component: LoginComponent,
  },
  {
    path: 'launchpad',
    title: 'Verato - Platform',
    component: LaunchpadComponent,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'accounts',
    title: 'Verato - Account Selector',
    component: AccountSelectorComponent,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'accounts/:userId',
    title: 'Verato - Account Selector',
    component: AccountSelectorComponent,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'environments',
    title: 'Verato - Environment Selector',
    component: EnvironmentSelectorComponent,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'environments/:accountId',
    title: 'Verato - Environment Selector',
    component: EnvironmentSelectorComponent,
    canActivate: [OktaAuthGuard],
  },
  {
    path: '',
    redirectTo: '/launchpad',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth, { CustomUserClaims, UserClaims } from '@okta/okta-auth-js';
import { interval, Subscription } from 'rxjs';
import { IAccountTest } from '../shared/models';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends OktaAuth  {
  private pollingInterval: number = 60000; // Polling interval in milliseconds
  private sessionSubscription: Subscription | undefined;

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
  ) {
    super(oktaAuth.options);
  }

  /**
   * Checks if the user session exists by attempting to renew or get the access token.
   * Clears storage and returns false if the access token is not available.
   * @returns A boolean indicating if the session exists.
   */
  public async sessionExists(): Promise<boolean> {
    try {
      const accessToken = await this.getOrRenewAccessToken();
      if (!accessToken) {
        this.clearStorage();
        return false;
      }
      return true;
    } catch (error: any) {
      this.logError('sessionExists', error);
      return false;
    }
  }

  /**
   * Starts polling to check if the session exists at regular intervals.
   * If the session does not exist, the user is logged out.
   */
  public sessionPolling(): void {
    this.logMessage('Starting session polling.');

    // Unsubscribe from any existing session subscription to prevent multiple polling instances
    if (this.sessionSubscription) {
      this.sessionSubscription.unsubscribe();
    }

    // Set up polling using RxJS interval
    this.sessionSubscription = interval(this.pollingInterval).subscribe(async () => {
      try {
        const sessionExists = await this.sessionExists();
        const time = new Date().toLocaleString();

        if (!sessionExists) {
          this.logMessage(`Polling: session expired at - ${time}`);
          await this.logout(); // Handle any logout errors gracefully
        } else {
          this.logMessage(`Polling: session active at - ${time}`);
        }
      } catch (error: any) {
        this.logError('sessionPolling', error);
      }
    });
  }

  /**
   * Logs the user out by clearing tokens before redirecting.
   */
  public async logout(): Promise<void> {
    try {
      await this.signOut({
        clearTokensBeforeRedirect: true,
      });
    } catch (error: any) {
      this.logError('logout', error);
    }
  }

  /**
   * Logs error messages with a consistent format for easier debugging.
   * @param context - The context or function where the error occurred.
   * @param error - The error object or message.
   */
  private logError(context: string, error: any): void {
    console.error(`ERROR in ${context}:`, error);
  }

  /**
   * Logs informational messages with a consistent format.
   * @param message - The message to log.
   */
  private logMessage(message: string): void {
    console.log(message);
  }

  /**
   * Cleanup method called when the service is destroyed.
   * Ensures that any active subscriptions are unsubscribed to prevent memory leaks.
   */
  ngOnDestroy(): void {
    if (this.sessionSubscription) {
      this.sessionSubscription.unsubscribe();
    }
  }
}



